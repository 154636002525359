import React from 'react';
import { Route, Switch, useLocation } from 'react-router-dom'


import Header from './components/Header'
import Footer from './components/Footer'

import Home from './pages/Home'
import EventModal from './components/EventModal'
import EventSearch from './pages/EventSearch'
import Error404 from './pages/Error404'
import Road from './pages/Road'
import Trail from './pages/Trail'
import VR from './pages/VR'
import EventDetail from './components/EventDetail'





//import NavBar from './components/NavBar'
import './App.css';


import CssBaseline from '@material-ui/core/CssBaseline';

import { makeStyles } from '@material-ui/core/styles';


import footerBackground from './images/footerbackground.jpg'



import InsertDataToDB from './pages/InsertDataToDB'



//import Link from '@material-ui/core/Link';

const useStyles = makeStyles(theme => ({
  icon: {
    marginRight: theme.spacing(2),
  },
  heroContent: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(8, 0, 6),
  },
  heroButtons: {
    marginTop: theme.spacing(4),
  },
  cardGrid: {
    paddingTop: theme.spacing(8),
    paddingBottom: theme.spacing(8),
  },
  card: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  cardMedia: {
    paddingTop: '56.25%', // 16:9
  },
  cardContent: {
    flexGrow: 1,
  },
  allPage: {
    backgroundColor: '#1F1F1F',
    [theme.breakpoints.up('xs')]: {
    },
    [theme.breakpoints.up('sm')]: {
    },
    [theme.breakpoints.up('md')]: {
      // width: `calc(100% - 240px)`,
      // marginLeft: '240px',
    },
  },
  mainDiv: {
    [theme.breakpoints.up('xs')]: {
      backgroundColor: '#FFFFFF'
    },
    [theme.breakpoints.up('sm')]: {
    },
    [theme.breakpoints.up('md')]: {
      paddingLeft: '0px',
      paddingRight: '0px',
      maxWidth: '960px',
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },
  // footer: {
  //   backgroundColor: theme.palette.background.paper,
  //   padding: theme.spacing(6),
  // },
}));

const cards = [1, 2, 3, 4, 5, 6, 7, 8, 9];

// const listEvent = gql`
//   query getListEvent {
//     events{
//       eventNameTH
//       eventNameEN
//       latitude
//       longtitude
//       priceStart
//       priceEnd
//       eventDate
//       coverPhotoUrl
//       raceType
//       bookableDateStart

//     }
//   }
// `;

const App = () => {
  const classes = useStyles();


  // }

  //console.log(data);
  //console.log(moment("2019-11-27").format)
  //alert(moment('2019-11-27').format())

  let location = useLocation();

  // This piece of state is set when one of the
  // gallery links is clicked. The `background` state
  // is the location that we were at when one of
  // the gallery links was clicked. If it's there,
  // use it as the location for the <Switch> so
  // we show the gallery in the background, behind
  // the modal.
  document.body.style.overflow = 'unset'
  let background = location.state && location.state.background;
  return (
    <div className={classes.allPage}>
      <CssBaseline />

      <Header />
      <main style={{ backgroundColor: "#1F1F1F" }}>
        <div className={classes.mainDiv}>
          <Switch location={background || location}>
            <Route exact path="/" component={Home} />
            <Route path="/eventSearch" component={EventSearch} />
            <Route path="/road" component={Road} />
            <Route path="/trail" component={Trail} />
            <Route path="/vr" component={VR} />
            <Route path="/event/:eventSlug" component={EventDetail} />
            {/* <Route path="/insertdatatodb" component={InsertDataToDB} /> */}
            <Route component={Error404} />
          </Switch>
          {/* Show the modal when a background page is set */}
          {background && <Route path="/event/:eventSlug" component={EventModal} />}
        </div>

      </main>
      {/* Footer */}
      <footer className={classes.footer} style={{ backgroundColor: "#1F1F1F" }} >
        {/* <div className={classes.mainDiv} style={{ backgroundColor: "#1F1F1F" }}> */}
        <Footer />
        {/* </div> */}
      </footer>
      {/* End footer */}
    </div>
  );
}





export default App;

import React from 'react';
import ReactDOM from 'react-dom';
import { ApolloProvider } from 'react-apollo'
import { BrowserRouter, Route, Switch } from 'react-router-dom'
import ApolloClient from 'apollo-boost'
import { MuiThemeProvider, createMuiTheme, CssBaseline } from '@material-ui/core'
import { indigo, pink } from '@material-ui/core/colors'

import DBHeavent from './css/DB_Heavent_v3.2.1.ttf';
import RSUTextFontBold from './css/RSU_Text_Bold.ttf';
import DBHeaventHevaticaXbd from './css/DBHelvethaicaXBdv3.2.ttf';


//import { registerObserver } from 'react-perf-devtool'
import './css/MainCss.css'

//import Index from './pages/index';
import App from './App';
import * as serviceWorker from './serviceWorker';


const dbHeavent = {
    fontFamily: 'DBHeavent',
    fontStyle: 'normal',
    fontWeight: 400,
    src: `
      url(${DBHeavent})
    `
    //unicodeRange: 'U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF',
};

const dbHeaventHevaticaXbd = {
    fontFamily: 'DBHeaventHevaticaXbd',
    fontStyle: 'normal',
    fontWeight: 400,
    src: `
      url(${DBHeaventHevaticaXbd})
    `
    //unicodeRange: 'U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF',
};

const rsuTextFont = {
    fontFamily: 'rsuTextFont',
    fontStyle: 'normal',
    fontWeight: 400,
    src: `
      url(${RSUTextFontBold})
    `
    //unicodeRange: 'U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF',
};


const theme = createMuiTheme({
    palette: {
        //type: 'dark',
        primary: indigo,
        secondary: pink,
    },
    typography: {
        fontFamily: 'DBHeavent, Arial',
        fontSize: 20,
    },
    overrides: {
        MuiCssBaseline: {
            '@global': {
                '@font-face': [dbHeavent, rsuTextFont, dbHeaventHevaticaXbd],
            },
        },
    },
    // typography: {
    //     useNextVariants: true,
    // },
})


const client = new ApolloClient({
    uri: process.env.REACT_APP_API_URL,
    //dataIdFromObject: o => o.id,
})
//console.log('test', process.env.REACT_APP_TEST)

ReactDOM.render(
    <ApolloProvider client={client}>
        <MuiThemeProvider theme={theme}>
            <CssBaseline />
            <BrowserRouter>
                <Switch>
                    <Route component={App} />
                </Switch>
            </BrowserRouter>
        </MuiThemeProvider>
    </ApolloProvider>,
    document.getElementById('root')
)

//ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
